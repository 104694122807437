import React from 'react'

const ShimmerCard = () => {
  return (
    <div className='w-36 md:w-48 m-4 bg-gray-700 animate-pulseOpacity h-80'>
        
 </div>
  )
}

export default ShimmerCard
