import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name:"user",
    initialState:null,
    reducers: {
        addUser: (state,action) => {
            return action.payload
        },
        removeUser : (state,action) => {
            // whatever returned is set in store
            return null;
        }
    }
})


export const {addUser,removeUser} = userSlice.actions;

export default userSlice.reducer