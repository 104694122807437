const lang = {
    en: {
        search : "Search",
        gptSearchBarText: "What would you like to watch today?"
    },
    hindi : {
        search : 'खोजे',
        gptSearchBarText: 'आज आप क्या देखना चाहेंगे?'
    },
    japanese : {
        search : 'けんさく',
        gptSearchBarText: '今日は何を見たいですか？'
    }
}

export default lang